<template>
    <v-form @submit.prevent="validate()">
        <h4 v-if="$vuetify.breakpoint.xs" :style="$vuetify.breakpoint.xs ? responsive_title : ''">Datos generales</h4>
        <h4 v-if="$vuetify.breakpoint.sm" :style="$vuetify.breakpoint.sm ? responsive_title : ''">Datos generales</h4>
        <v-row>
            <v-col lg="4" xl="4" sm="12" md="4" cols="12">
                <v-text-field v-model="input_primer_nombre" :error-messages="PrimerNombreErrors"
                    @blur="$v.input_primer_nombre.$touch()" label="Primer nombre*" outlined></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" xl="4" sm="12" xs="12" md="4">
                <v-text-field v-model="input_segundo_nombre" :error-messages="SegundoNombreErrors"
                    @blur="$v.input_segundo_nombre.$touch()" label="Segundo nombre" outlined></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" xl="4" sm="12" xs="4" md="4">
                <v-text-field v-model="input_tercer_nombre" :error-messages="TercerNombreErrors"
                    @blur="$v.input_tercer_nombre.$touch()" label="Tercer nombre" outlined></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" lg="4" xl="4" sm="12" xs="4" md="4">
                <v-text-field v-model="input_primer_apellido" :error-messages="PrimerApellidoErrors"
                    @blur="$v.input_primer_apellido.$touch()" label="Primer apellido*" outlined></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" xl="4" sm="12" xs="4" md="4">
                <v-text-field label="Segundo apellido" :error-messages="SegundoApellidoErrors"
                    @blur="$v.input_segundo_apellido.$touch()" outlined v-model="input_segundo_apellido"></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" xl="4" sm="12" xs="4" md="4">
                <v-text-field label="Apellido casada" :error-messages="TercerApellidoErrors"
                    @blur="$v.input_apellido_casada.$touch()" outlined v-model="input_apellido_casada"></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" lg="4" xl="4" sm="12" xs="4" md="4">
                <v-checkbox label="Soy menor de edad" v-model="input_menor_edad"></v-checkbox>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" lg="4" xl="4" sm="12" xs="4" md="4">
                <v-autocomplete v-model="input_nacionalidad" :error-messages="NacionalidadErrors"
                    @blur="$v.input_nacionalidad.$touch()" label="Nacionalidad*" outlined item-text="name" item-value="id"
                    :items="catalogo_nacionalidad"></v-autocomplete>
            </v-col>
            <v-col cols="12" lg="4" xl="4" sm="12" xs="4" md="4">
                <v-autocomplete :disabled="input_menor_edad" label="Tipo documento*" clearable
                    v-model="input_tipo_documento" :error-messages="TipoDocumentoErrors"
                    @blur="$v.input_tipo_documento.$touch()" outlined item-text="name" item-value="id"
                    :items="catalogo_tipo_documento"></v-autocomplete>
            </v-col>
            <v-col cols="12" lg="4" xl="4" sm="12" xs="4" md="4">
                <v-text-field label="Número de documento*"  :disabled="input_menor_edad" v-mask="mascaras"
                    :error-messages="NumeroDocumentoErrors" @blur="$v.input_numero_documento.$touch()" outlined
                    v-model="input_numero_documento" @input="input_numero_documento = input_numero_documento.toUpperCase();"></v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" lg="4" xl="4" sm="12" xs="4" md="4">
                <v-autocomplete label="Sexo*" outlined item-text="name" :error-messages="SexoErrors"
                    @blur="$v.input_sexo.$touch()" item-value="id" :items="catalogo_sexo"
                    v-model="input_sexo"></v-autocomplete>
            </v-col>
            <v-col cols="12" lg="4" xl="4" sm="12" xs="4" md="4">
                <v-autocomplete label="Propósito del uso*" :error-messages="PropositoUsoErrors"
                    @blur="$v.input_proposito_uso.$touch()" outlined item-text="name" item-value="id"
                    :items="catalogo_proposito_uso" v-model="input_proposito_uso"></v-autocomplete>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { required, requiredIf, numeric, integer, helpers, maxLength,  } from "vuelidate/lib/validators";
const alpha = helpers.regex('alpha', /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]*$/);
const numeroDocumento = helpers.regex('numeroDoc', /^([A-Z]{1}[0-9]{8})$/);

export default {
    name: 'step1',
    data: () => ({
        input_primer_nombre: null,
        input_segundo_nombre: null,
        input_tercer_nombre: null,
        input_primer_apellido: null,
        input_segundo_apellido: null,
        input_apellido_casada: null,
        input_menor_edad: false,
        input_nacionalidad: null,
        input_sexo: null,
        input_tipo_documento: null,
        input_proposito_uso: null,
        input_numero_documento: null,
        catalogo_nacionalidad: [],
        catalogo_sexo: [],
        catalogo_tipo_documento: [],
        catalogo_proposito_uso: [],
        responsive_title: 'display: flex; justify-content: center; color: #697182; font-weight: 600; font-size: 19px; margin-bottom: 20px;',

    }),
    validations: {
        input_primer_nombre: {
          required,
          alpha,
          maxLength: maxLength(20),
        },
        input_segundo_nombre: { alpha, maxLength: maxLength(20) },
        input_tercer_nombre: { alpha, maxLength: maxLength(20) },
        input_primer_apellido: { required, alpha, maxLength: maxLength(20) },
        input_segundo_apellido: { alpha, maxLength: maxLength(20) },
        input_apellido_casada: { alpha, maxLength: maxLength(20) },
        input_nacionalidad: { required },
        input_tipo_documento: {
            required: requiredIf(function (data) {
                return !data.input_menor_edad
            }),
            numeric,
            integer
        },
        input_numero_documento: {
            required: requiredIf(function (data) {
                return !data.input_menor_edad
            }),
            numeroDocumento
            // numeric,
            // integer
        },
        input_sexo: { required },
        input_proposito_uso: { required },
    },
    methods: {

        async ctl_nacionalidad() {
            const getNacionalidades = await this.services.registro.ctl_nacionalidad();
            this.catalogo_nacionalidad = getNacionalidades.data;
        },
        async getsexo() {
            const get_ctl_sexo = await this.services.registro.ctl_sexo();
            this.catalogo_sexo = get_ctl_sexo.data;

        },
        async getTipoDocumento() {
            const get_ctl_tipo_documento = await this.services.registro.ctl_tipo_documento();
            this.catalogo_tipo_documento = get_ctl_tipo_documento.data;


        },
        async getPropositoUso() {
            const get_ctl_proposito_uso = await this.services.registro.ctl_proposito_uso();
            this.catalogo_proposito_uso = get_ctl_proposito_uso.data;

        },
        async validate() {
            this.$v.$touch();
            return this.$v.$invalid;
        },
        datosFomulario() {
            return {
                input_primer_nombre: this.input_primer_nombre,
                input_segundo_nombre: this.input_segundo_nombre,
                input_tercer_nombre: this.input_tercer_nombre,
                input_primer_apellido: this.input_primer_apellido,
                input_segundo_apellido: this.input_segundo_apellido,
                input_apellido_casada: this.input_apellido_casada,
                input_menor_edad: this.input_menor_edad,
                input_nacionalidad: this.input_nacionalidad,
                input_sexo: this.input_sexo,
                input_tipo_documento: this.input_tipo_documento,
                input_proposito_uso: this.input_proposito_uso,
                input_numero_documento: this.input_numero_documento,
            };
        }
    },
    computed: {
        mascaras() {
            if (this.input_tipo_documento === 1) return "########-#"
            if (this.input_tipo_documento === 2) return "A########"
            return "";
        },

        PrimerNombreErrors() {
            const errors = [];
            if (!this.$v.input_primer_nombre?.$dirty) return errors;
            if (!this.$v.input_primer_nombre?.required) {
                errors.push("Este campo es requerido");
            }
            if (!this.$v.input_primer_nombre?.alpha) {
                errors.push("Campo primer nombre solo acepta caracteres alfabéticos")
            }
            if (!this.$v.input_primer_nombre?.maxLength) {
                errors.push('Máximo de caracteres 20')
            }

            return errors;
        },
        SegundoNombreErrors() {
            const errors = [];
            if (!this.$v.input_segundo_nombre?.$dirty) return errors;
            // if (!this.$v.input_segundo_nombre?.required) {
            //     errors.push("Este campo es requerido");
            // }
            if (!this.$v.input_segundo_nombre?.alpha) {

                errors.push("Campo segundo nombre solo acepta caracteres alfabéticos")
            }
            if (!this.$v.input_segundo_nombre?.maxLength) {
                errors.push('Máximo de caracteres 20')
            }

            return errors;
        },
        PrimerApellidoErrors() {
            const errors = [];
            if (!this.$v.input_primer_apellido?.$dirty) return errors;
            if (!this.$v.input_primer_apellido?.required) {
                errors.push("Este campo es requerido");
            }
            if (!this.$v.input_primer_apellido?.alpha) {

                errors.push("Campo primer apellido solo acepta caracteres alfabéticos")
            }
            if (!this.$v.input_primer_apellido?.maxLength) {
                errors.push('Máximo de caracteres 20')
            }
            return errors;
        },
        TercerNombreErrors() {
            const errors = [];
            if (!this.$v.input_tercer_nombre?.$dirty) return errors;
            if (!this.$v.input_tercer_nombre?.alpha) {

                errors.push("Campo tercer nombre solo acepta caracteres alfabéticos")
            }
            if (!this.$v.input_tercer_nombre?.maxLength) {
                errors.push('Máximo de caracteres 20')
            }
            return errors;
        },
        SegundoApellidoErrors() {
            const errors = [];
            if (!this.$v.input_segundo_apellido?.$dirty) return errors;
            // if (!this.$v.input_segundo_apellido?.required) {
            //     errors.push("Este campo es requerido");
            // }
            if (!this.$v.input_segundo_apellido?.alpha) {
                errors.push("Campo segundo apellido solo acepta caracteres alfabéticos")
            }
            if (!this.$v.input_segundo_apellido?.maxLength) {
                errors.push('Máximo de caracteres 20')
            }
            return errors;
        },
        TercerApellidoErrors() {
            const errors = [];
            if (!this.$v.input_apellido_casada?.$dirty) return errors;
            if (!this.$v.input_apellido_casada?.alpha) {

                errors.push("Campo apellido casada solo acepta caracteres alfabéticos")
            }
            if (!this.$v.input_apellido_casada?.maxLength) {
                errors.push('Máximo de caracteres 20')
            }
            return errors;
        },
        NacionalidadErrors() {
            const errors = [];
            if (!this.$v.input_nacionalidad?.$dirty) return errors;
            if (!this.$v.input_nacionalidad?.required) {
                errors.push("Este campo es requerido");
            }
            return errors;
        },
        SexoErrors() {
            const errors = [];
            if (!this.$v.input_sexo?.$dirty) return errors;
            if (!this.$v.input_sexo?.required) {
                errors.push("Este campo es requerido");
            }
            return errors;
        },
        TipoDocumentoErrors() {
            const errors = [];
            if (!this.$v.input_tipo_documento?.$dirty) return errors;
            if (this.$v.input_tipo_documento?.$error) {
                errors.push("Este campo es requerido");
            }
            return errors;
        },
        NumeroDocumentoErrors() {
            const errors = [];
            if (!this.$v.input_numero_documento?.$dirty) return errors;
            if (this.$v.input_numero_documento?.$error) {
                errors.push("Este campo es requerido");
            }
            if (!this.$v.input_numero_documento?.numeroDocumento){
                errors.push("El formato es incorrecto");
            }
            return errors;
        },
        PropositoUsoErrors() {
            const errors = [];
            if (!this.$v.input_proposito_uso?.$dirty) return errors;
            if (!this.$v.input_proposito_uso?.required) {
                errors.push("Este campo es requerido");
            }
            return errors;
        },
    },
    created() {
        this.ctl_nacionalidad();
        this.getsexo();
        this.getTipoDocumento();
        this.getPropositoUso();
    },

    watch: {
        input_menor_edad(newValue) {
            if (!!newValue) {
                this.input_tipo_documento = null;
                this.input_numero_documento = null;
            }
        }
    },
}
</script>
<style scoped>
.v-text-field.v-text-field--enclosed {
    margin: -10px 0 -10px 0 !important;
}
</style>

<template>
    <v-app>
        <v-container>
            <v-main>
                <div class="text-h3 text-center"
                    :style="responsive_title">
                    Bienvenido
                </div>
                <p  :style="responsive_subtitle">Te invitamos a que te registres y te conectes con el mundo digital de una manera rápida y segura</p>

                <v-stepper non-linear alt-labels elevation="0" v-model="step_actual">
                    <v-stepper-header :style="responsive_header_steps">
                        <template v-for="(step, i) in pasos_steps">
                            <v-stepper-step :step="step.id_step" :alt-labels="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? true : true" color="#30A4F1">{{ step.label }}</v-stepper-step>
                            <v-divider v-if="step.id_step !== 2" :key="i"></v-divider>
                        </template>
                    </v-stepper-header>
                    <v-stepper-items>
                        <v-stepper-content class="px-15" :key="index" v-for="(step, index) in pasos_steps"
                            :step="step.id_step">

                            <component class="mt-5" :is="step.component" :ref="step.ref"></component>

                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
                <div class="d-flex justify-center mb-10" style="gap: 35px;">
                    <v-btn v-if="step_actual === 1" @click="backLogin()" rounded outlined style="border-color: #004FD1; color: #004FD1; text-transform: capitalize;">
                        Cancelar
                    </v-btn>
                    <v-btn v-if="step_actual === 2" rounded outlined style="border-color: #004FD1; color: #004FD1; text-transform: capitalize;" @click="anteriorStep()">
                        Atras
                    </v-btn>
                    <v-btn v-if="step_actual === 1" rounded style="background-color: #30A4F1; color: white; text-transform: capitalize;"
                        @click="siguienteStep()">
                        Siguiente
                    </v-btn>
                    <v-btn v-if="step_actual === 2" rounded style="background-color: #30A4F1; color: white; text-transform: capitalize;"
                        @click="registro()">
                        Registrarme
                    </v-btn>
                </div>
            </v-main>
        </v-container>
    </v-app>
</template>

<script>
import step1 from "./steps/step1.vue";
import step2 from "./steps/step2.vue";
export default {
    name: 'registro',
    components: { step1, step2 },
    data: () => ({
        step_actual: 1,
        formStep1: null,
        pasos_steps: [
            {
                id_step: 1,
                label: 'Paso',
                component: 'step1',
                ref: 'step1'
            },
            {
                id_step: 2,
                label: 'Paso',
                component: 'step2',
                ref: 'step2'
            }
        ],
        responsive_title: '',
        responsive_subtitle: '',
        responsive_header_steps: '',
    }),
    methods: {
        async siguienteStep() {

            let ref = this.$refs?.step1[0];


            if (this.step_actual === this.pasos_steps.length) {
                return;
            }

            if (this.step_actual === 1) {

                if (await ref.validate() === true) {

                    return;
                } else {
                    const form1 = this.$refs?.step1[0].datosFomulario();
                    this.formStep1 = form1;

                    this.step_actual = 2;
                }
            }

        },
        anteriorStep() {
            if (this.step_actual === 2) {
                this.step_actual = 1;
            } else {
                return;
            }
        },
        backLogin() {
            this.$router.push({ name: 'login', }).catch();
        },
        async registro() {
            let refForm2 = this.$refs?.step2[0];

            if (await refForm2.validate() === true) {

                return;
            } else {
                const form2 = this.$refs?.step2[0].datosForm2();

                const registro = {
                    "primer_nombre": this.formStep1.input_primer_nombre && this.formStep1.input_primer_nombre.trim(),
                    "segundo_nombre": this.formStep1.input_segundo_nombre && this.formStep1.input_segundo_nombre.trim(),
                    "tercer_nombre": this.formStep1.input_tercer_nombre && this.formStep1.input_tercer_nombre.trim(),
                    "primer_apellido": this.formStep1.input_primer_apellido && this.formStep1.input_primer_apellido.trim(),
                    "segundo_apellido": this.formStep1.input_segundo_apellido && this.formStep1.input_segundo_apellido.trim(),
                    "apellido_casada": this.formStep1.input_apellido_casada && this.formStep1.input_apellido_casada.trim(),
                    "menor_edad": this.formStep1.input_menor_edad,
                    "id_nacionalidad": this.formStep1.input_nacionalidad,
                    "id_genero": this.formStep1.input_sexo,
                    "id_tipo_documento": this.formStep1.input_tipo_documento,
                    "id_proposito_uso": this.formStep1.input_proposito_uso,
                    "numero_documento": this.formStep1.input_numero_documento && this.formStep1.input_numero_documento.trim(),
                    "email": form2.input_email,
                    "password": form2.input_password,
                };

                try {
                    const fetchRegistro = await this.services.registro.postRegistro(registro);
                    console.log(fetchRegistro);
                    this.$router.push({
                        name: 'login',
                    }).catch();
                    this.temporalAlert({
                        show: true,
                        message: "Usuario registrado correctamente",
                        type: "success",
                    });
                } catch (error) {

                    if (error.response?.status === 400) {
                        for (let item of error.response.data) {
                            this.temporalAlert({
                            show: true,
                            message: item.message || "Usuario no se pudo registrar",
                            type: "error",
                        });

                        }

                    } else if (error.response?.status === 422){
                        this.temporalAlert({
                            show: true,
                            message: error.response?.data.err.description || "Usuario no se pudo registrar",
                            type: "error",
                        });
                    } else {
                        this.temporalAlert({
                            show: true,
                            message: "No se pudo registrar el usuario",
                            type: "error",
                        })
                    }


                }
            }


        },
        handleResize() {
            if (this.$vuetify.breakpoint.xs) {

                this.responsive_title = 'margin-top: 50px; font-weight: 700; color: #30A4F1; font-size: 14px; text-transform: uppercase;';
                this.responsive_subtitle = 'text-align: center; margin-top: 10px; margin-bottom: 5px;  padding-right: 25px; padding-left:25px; font-weight: 400; font-size: 16px; color: #30A4F1;';
                this.responsive_header_steps = 'margin-bottom: -50px;'
            }

            if (this.$vuetify.breakpoint.sm) {

                this.responsive_title = 'margin-top: 50px; font-weight: 700; color: #30A4F1; font-size: 14px; text-transform: uppercase;';
                this.responsive_subtitle = 'display:flex; justify-content:center; text-align: center;  padding-right: 25px; padding-left:25px;  margin-top: 10px; margin-bottom: 5px; font-weight: 400; font-size: 22px; color: #30A4F1;';
                this.responsive_header_steps = 'margin-bottom: -50px;'
            }

            if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg) {

                this.responsive_title = 'margin-top: 120px; font-weight: 700; color: #30A4F1; font-size: 40px; line-height: 36px; text-transform: uppercase;'
                this.responsive_subtitle = 'display: flex; padding-left: 120px; padding-right: 120px; justify-content: center; text-align: center; margin-top: 20px; margin-bottom: 20px; font-weight: 400; font-size: 34px; color: #697182; line-height: 36px;'

            }

            if (this.$vuetify.breakpoint.xl ){
                this.responsive_title = 'margin-top: 120px; font-weight: 700; color: #30A4F1; font-size: 40px; line-height: 36px; text-transform: uppercase;'
                this.responsive_subtitle = 'display: flex; justify-content: center; text-aling: justify; margin-top: 20px; margin-bottom: 20px;  padding-right: 25px; padding-left:25px; font-weight: 400; font-size: 34px; color: #697182; line-height: 36px;'

            }
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    }
}
</script>
<style scoped>
.v-divider {
    max-width: 250px !important;
}

.v-stepper__header {
    box-shadow: none !important;
    justify-content: center !important;
}

@media only screen and (max-width: 959px) {
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: block !important;
  }
}


</style>

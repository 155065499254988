<template>
    <v-form @submit.prevent="validate()">
        <h4 v-if="$vuetify.breakpoint.xs" :style="$vuetify.breakpoint.xs ? responsive_title : ''">Credenciales</h4>
        <h4 v-if="$vuetify.breakpoint.sm" :style="$vuetify.breakpoint.sm ? responsive_title : ''">Credenciales</h4>
        <v-row>
            <v-col cols="12" offset="0" offset-sm="0" offset-md="4" sm="12" md="4">
                <v-row>
                    <v-col lg="12" xl="12" sm="12" md="12" cols="12">
                        <v-text-field v-model="input_email" :error-messages="EmailErrors" @blur="$v.input_email.$touch()"
                            label="Correo electrónico*" outlined></v-text-field>
                    </v-col>

                    <v-col lg="12" xl="12" sm="12" md="12" cols="12">
                        <v-text-field label="Contraseña*" type="password" :error-messages="PasswordErrors"
                            @blur="$v.input_password.$touch()" v-model="input_password" outlined></v-text-field>
                    </v-col>

                    <v-col class="mb-0 pb-0" lg="12" xl="12" sm="12" md="12" cols="12">
                        <v-text-field label="Confirmar contraseña*" :error-messages="passwordRepeatErrors"
                            @blur="$v.input_confirmar_password.$touch()" type="password" v-model="input_confirmar_password"
                            outlined></v-text-field>
                    </v-col>
                    <v-col class="mt-0 pt-0 d-flex" lg="12" xl="12" sm="12" md="12" cols="12">
                        <v-checkbox label="Acepto" :error-messages="TerminosCondicionesErrors" v-model="input_terminos_condiciones"></v-checkbox>
                          <a class="black--text mt-5 px-1" @click="openModalTerminosCondiciones()">
                            términos y condiciones
                          </a>

                    </v-col>
                </v-row>
            </v-col>
        </v-row>
      <v-dialog v-model="openModalTerminos" width="900">
        <v-card>
          <v-card-title style="display: flex; justify-content: center;">
            <h1 :class="responsiveTitleTerminosCondiciones" >
              Términos y condiciones
            </h1>
          </v-card-title>
          <v-card-text class="d-flex justify-center">
            <div class="text-justify" style="width: 750px;">
              <p>
                La página web "Conectando El Salvador" es una iniciativa del Gobierno de la República de El Salvador,
                que tiene como propósito ofrecer una interfaz accesible, clara y organizada que presenta una amplia variedad de
                plataformas disponibles a nivel de Gobierno, ofreciendo acceso a los módulos tales como: Diario El Salvador,
                Formación, Ventanilla de Trámites (Simple SV), Recreación, Sistema de Atención Ciudadana y Mercado Laboral.
                Los módulos antes referidos, tienen como objetivo proporcionar a los usuarios una experiencia integral en línea,
                abarcando una amplia gama de temas de relevancia en El Salvador.
              </p>
              <p>
                El acceso y uso de la página web "Conectando El Salvador" implica la aceptación y cumplimiento de los siguientes términos y condiciones:
              </p>
              <ul>
                <li>
                  El usuario se compromete a utilizar la página web y sus contenidos de forma lícita, ética y responsable, respetando las leyes de la República de El Salvador vigentes, la propiedad intelectual,
                  incluyendo marcas y derechos de autor de cada uno de los módulos, así como la privacidad y la seguridad de los demás usuarios y de terceros.
                </li>
                <li>
                  El usuario es responsable de los datos e información que proporcione o comparta a través de la página web, así como de las consecuencias que se deriven de su uso indebido o fraudulento.
                </li>
                <li>
                  El usuario reconoce que el Gobierno de la República de El Salvador es el titular de los derechos de propiedad intelectual e industrial sobre la página web y sus contenidos, salvo que se indique lo contrario.
                </li>
                <li>
                  El usuario no podrá copiar, reproducir, distribuir, modificar, transmitir, publicar o explotar comercialmente la página web o sus contenidos sin el consentimiento previo y por escrito del Gobierno de la República de El Salvador a través de los titulares correspondientes.
                </li>
                <li>
                  El usuario acepta que el Gobierno de la República de El Salvador se reserva el derecho de modificar, suspender o cancelar la página web o sus contenidos en cualquier momento y sin previo aviso, así como de establecer o modificar las condiciones de acceso y uso de la misma.
                </li>
                <li>
                  El usuario acepta que el Gobierno de la República de El Salvador podrá utilizar cookies u otros mecanismos similares para recabar información sobre las preferencias y hábitos del usuario con fines estadísticos, publicitarios o de mejora del servicio.
                </li>
                <li>
                  El usuario podrá configurar su navegador para rechazar o eliminar las cookies si lo desea.
                </li>
                <li>
                  El usuario acepta y otorga el consentimiento que el Gobierno de la República de El Salvador podrá tratar los datos e información del usuario en el marco de las leyes aplicables.
                </li>
                <li>
                  El usuario acepta que el uso de la página web "Conectando El Salvador" se rige por las leyes y tribunales del departamento de San Salvador, República de El Salvador.
                </li>
                <li>
                  El usuario acepta que el Gobierno de la República de El Salvador no garantiza la disponibilidad, continuidad, calidad, exactitud, veracidad o actualidad de la página web o sus contenidos, ni se hace responsable por los daños y perjuicios que puedan ocasionarse al usuario o a terceros por el acceso o uso de la página web o sus contenidos.
                </li>

              </ul>
              <p class="mt-2">
                Al acceder y usar la página web "Conectando El Salvador", el usuario declara haber leído, entendido y aceptado los términos y condiciones aquí expuestos.
              </p>
            </div>
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
              <v-btn class="mb-5 mt-0 px-5" style="color: white" color="#30A4F1" @click="closeDialog()">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>



</template>

<script>
import { required, email, sameAs, minLength, requiredIf } from "vuelidate/lib/validators";
export default {
    name: 'step2',
    data: () => ({
        input_email: null,
        input_password: null,
        input_confirmar_password: null,
        input_terminos_condiciones: undefined,
        responsive_title: 'text-align:center; color: #697182; font-weight: 600; font-size: 19px; margin-bottom: 20px;',
        openModalTerminos: false,
        responsiveTitleTerminosCondiciones: '',
    }),
    validations: {
        input_email: { required, email },
        input_password: { required, minLength: minLength(8) },
        input_confirmar_password: { sameAsPassword: sameAs('input_password') },
        input_terminos_condiciones: { required },
    },
    methods: {
        datosForm2() {
            return {
                input_email: this.input_email,
                input_password: this.input_password,
                input_confirmar_password: this.input_confirmar_password
            }
        },
        async validate() {
            this.$v.$touch();
            return this.$v.$invalid;
        },
        openModalTerminosCondiciones() {
          this.openModalTerminos = true;
        },
      handleResize() {

        if (this.$vuetify.breakpoint.xs) {
          this.responsiveTitleTerminosCondiciones = "title_terminos_codiciones_xs_sm";

        }
        if (this.$vuetify.breakpoint.sm) {
        }
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg) {
        }

        if (this.$vuetify.breakpoint.xl) {
          this.responsiveTitleTerminosCondiciones = "title_terminos_codiciones_xl";
        }
      },
      closeDialog() {
          this.openModalTerminos = false;
      }
    },
    computed: {
        EmailErrors() {
            const errors = [];
            if (!this.$v.input_email?.$dirty) return errors;
            if (!this.$v.input_email?.required) {
                errors.push("Este campo es requerido");
            } else if (!this.$v.input_email?.email) {
                errors.push("Ingrese un formato de correo válido.");
            }
            return errors;
        },
        PasswordErrors() {
            const errors = [];
            if (!this.$v.input_password?.$dirty) return errors;
            if (!this.$v.input_password?.required) {
                errors.push("Este campo es requerido");
            } else if (!this.$v.input_password?.minLength) {
                errors.push('Longitud menor a la esperada');
            }
            return errors;
        },
        passwordRepeatErrors() {
            const errors = [];
            if (!this.$v.input_confirmar_password?.$dirty) return errors;
            if (!this.$v.input_confirmar_password?.sameAsPassword) {
                errors.push('Contraseñas no coinciden');
            }
            return errors;
        },
        TerminosCondicionesErrors() {
            const errors = [];
            if (!this.$v.input_terminos_condiciones?.$dirty) return errors;
            if (!this.$v.input_terminos_condiciones?.required) {
              errors.push("Debes aceptar los términos y condiciones");
            }
            return errors;
        },

    },
    watch: {
            input_terminos_condiciones(newValue) {
                if (newValue === false){
                    this.input_terminos_condiciones = undefined;
                }
            }
        },
  created() {
      window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
      window.removeEventListener('resize', this.handleResize)
  },
  mounted() {
      window.addEventListener('resize', this.handleResize)
    this.handleResize();
  },
  beforeDestroy() {
      window.removeEventListener('resize', this.handleResize)
  }
}
</script>
<style scoped>
.v-text-field.v-text-field--enclosed {
    margin: -10px 0 -10px 0 !important;
}

  .title_terminos_codiciones_xl {
    margin-top: 35px;
    margin-bottom: 25px;
    text-align: center;
    color: #30A4F1;
    font-weight: bold;

  }

.title_terminos_codiciones_xs_sm {
    font-size: 18px;
  margin-top: 25px;
  margin-bottom: 15px;
  text-align: center;
  color: #30A4F1;
  font-weight: bold;
  }

p{
    color: black;
    font-size: 16px;
  }

  ul li {
    font-size: 16px;
    color: black;
    margin-bottom: 10px;
  }

</style>
